export const isEmpty = (value: any) : boolean=> {
  // null, undefined 체크
  if (value === null || value === undefined) {
    return true;
  }
  // 데이터 타입 확인 후 '', [], {} 체크
  // 문자열 빈 공백 확인
  if (typeof value === "string" && value === "") {
    return true;
  }
  // 배열 빈 배열 확인
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  // 객체 빈 객체 확인
  if (typeof value === "object" && Object.keys(value).length === 0) {
    return true;
  }
  return false;
}

export const isNotEmpty = (value: any) : boolean => {
  return !isEmpty(value);
}

export const isEditEmpty = (value: string) : boolean => {
  if( isEmpty(value) ) return true;
  if( value === "<p><br></p>") return true;
  return false;
}

export const isNotEmptyEdit = (value: string) : boolean => {
  return !isEditEmpty(value);
}

// 공백 있는지 확인
export const isTrim = (str: string) : boolean => {
  return str.indexOf(' ') !== -1;
}

export function safeValue<T>(value: T|undefined, defaultValue: T) {
  if( Array.isArray(value) ) {
    return value.length > 0 ? value[0] : defaultValue;
  }
  if( value instanceof String || typeof value === 'string') {
    return value.length > 0 ? value : defaultValue;
  }
  if( value instanceof Object ) {
    return Object.keys(value).length > 0 ? value : defaultValue;
  }
  return value ?? defaultValue;
}

export function uniqueIdString() {
  return (Math.random() * 100000000000000000).toString(36);
}
export function uniqueIdNumber() {
  return Math.random() * 100000000000000000;
}

export function max(...values: number[]) {
  return Math.max(...values);
}

export function newTab(url:string) {
  // window.open(url, "_blank", "noopener, noreferrer");
  window.open(url, "_blank");
}
export function selfTab(url:string) {
  window.open(url, "_self");
}