import {createSlice} from "@reduxjs/toolkit";
import {authApi, LoginOtpResponse, LoginPwdResponse} from "../service/authApi";
import {User} from "../../../interface/commonInterface";
import {isNotEmpty} from "../../../util/commonUtil";
import {localClear} from "../../../util/localStorage";

type AuthState = {
  user: User | null;
  token: string | null;
  errorMsg: string[];
  isError: boolean;
}

const initialState: AuthState = {
  user: null,
  token: null,
  errorMsg: [],
  isError: false,
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.user = action.payload.user;
      state.token = action.payload.token;
      },
    logout(state) {
      state.user = null;
      state.token = null;
      sessionStorage.clear();
      localClear();
    },
    addError(state, action) {
      state.isError = true;
      state.errorMsg = Array.from(new Set([...state.errorMsg, action.payload]));
    },
    clearError(state) {
      state.isError = initialState.isError;
      state.errorMsg = initialState.errorMsg;
    },
    saveJwtToken(state, action) {
      state.token = action.payload.token;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.loginPwd.matchFulfilled,
      (state, action) => {
        console.log(`extraReducers loginPwd, matchFulfilled action.payload: ${JSON.stringify((action.payload) as LoginPwdResponse)}`);
        if( !action.payload ) {
          state.isError = true;
          state.errorMsg = Array.from(new Set([...state.errorMsg, "로그인에 실패하였습니다."]));
          return;
        }
        if( action.payload.total_count === 0 ) {
          state.isError = true;
          state.errorMsg = Array.from(new Set([...state.errorMsg, ...action.payload.errors]));
        } else {
          state.user = action.payload.data[0];
        }

      },
    ).addMatcher(
      authApi.endpoints.loginOtp.matchFulfilled,
      (state, action) => {
        console.log(`extraReducers loginOtp, action.payload: ${JSON.stringify((action.payload) as LoginOtpResponse)}`);
        if( !action.payload ) {
          state.isError = true;
          state.errorMsg = Array.from(new Set([...state.errorMsg, "로그인 실패로 OTP 재 요청 후 시도해 주세요."]));
          return;
        }
        if( isNotEmpty(action.payload.token) ) {
          state.token = action.payload.token;
        }
      }
    ).addMatcher(
      authApi.endpoints.loginOtp.matchPending, // Loading 상태
      (state) => {
        // console.log(`extraReducers loginOtp, matchPending`);
      }
    ).addMatcher(
      authApi.endpoints.loginOtp.matchRejected, // Error 상태
      (state, action) => {
        console.log(`extraReducers loginOtp, matchRejected`, state, action);
      }
    ).addMatcher( // authSlice logout 을 사용하고 authApi logout 을 지울 예정 임시로 작성해놓음
      authApi.endpoints.logout.matchFulfilled,
      (state) => {
        state.user = initialState.user;
        state.token = initialState.token;
        state.errorMsg = initialState.errorMsg;
        state.isError = initialState.isError;
      }
    )
  }
});

export const {
  login,
  logout,
  addError,
  clearError,
  saveJwtToken,
} = authSlice.actions;

export const selectUser = (state: { auth: AuthState }) => state.auth.user;
export const selectIsError = (state: { auth: AuthState }) => state.auth.isError;
export const selectErrorMsg = (state: { auth: AuthState }) => state.auth.errorMsg;
export const selectToken = (state: { auth: AuthState }) => state.auth.token;

export default authSlice.reducer;