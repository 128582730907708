import React from "react";
import {CommonModalProps} from "./Modal";


export interface ModalsProps<T> {
  name: MODAL_NAME_TYPE;
  modalProps: CommonModalProps<T>;
  // componentLazy 를 store 에 넣게되면 store 에서 빼서 사용할 수 없음
  // component: React.LazyExoticComponent<any>;
}
export interface ModalsComponent {
  name: MODAL_NAME_TYPE;
  component: React.LazyExoticComponent<any>;
}

// 모달 이름 등록
export enum MODAL_NAME_TYPE {
  ALERT_MODAL = "AlertModal",
  DESC_MODAL = "DescModal",
  INPUT_MODAL = "InputModal",
  USER_MODAL = "UserModal",
  USER_MODIFY_MODAL = "UserModifyModal",
}


// 모달 props 등록 (초기값)
export const modalsProps :ModalsProps<object>[] = [
  {
    name: MODAL_NAME_TYPE.USER_MODAL,
    modalProps: {
      name: MODAL_NAME_TYPE.USER_MODAL,
      isShow: false,
    },
  },
  {
    name: MODAL_NAME_TYPE.USER_MODIFY_MODAL,
    modalProps: {
      name: MODAL_NAME_TYPE.USER_MODIFY_MODAL,
      isShow: false,
    }
  },
  {
    name: MODAL_NAME_TYPE.INPUT_MODAL,
    modalProps: {
      name: MODAL_NAME_TYPE.INPUT_MODAL,
      isShow: false,
    }
  },
  {
    name: MODAL_NAME_TYPE.DESC_MODAL,
    modalProps: {
      name: MODAL_NAME_TYPE.DESC_MODAL,
      isShow: false,
    }
  },
  { // 가장 마지막에 추가 되어야함 (모든 모달들보다 경고가 가장 우선순위가 높아야하기 때문에)
    name: MODAL_NAME_TYPE.ALERT_MODAL,
    modalProps: {
      name: MODAL_NAME_TYPE.ALERT_MODAL,
      isShow: false,
    }
  },


];

// 모달 component 등록
export const modalsComponent :ModalsComponent[] = [
  {
    name: MODAL_NAME_TYPE.USER_MODAL,
    component: React.lazy(() => import("./UserAddModal") as any),
  },

  {
    name: MODAL_NAME_TYPE.USER_MODIFY_MODAL,
    component: React.lazy(() => import("./UserModifyModal") as any),
  },
  {
    name: MODAL_NAME_TYPE.INPUT_MODAL,
    component: React.lazy(() => import("./InputModal") as any),
  },
  {
    name: MODAL_NAME_TYPE.DESC_MODAL,
    component: React.lazy(() => import("./DescModal") as any),
  },
  {
    name: MODAL_NAME_TYPE.ALERT_MODAL,
    component: React.lazy(() => import("./AlertModal") as any),
  },
];
