import {api, tagTypes} from "../api";
import {CommonResponse, PageDataResponse, User} from "../../../interface/commonInterface";


// CommonResponse<UserResponse<Users>>

export interface BoardSearchCommonRequest {
  page: number;
  size: number;
  sort: string;
}
export interface UsersSearchRequest extends BoardSearchCommonRequest {
  username: string;
  useNm: string;
}

const TAG = 'Users';
tagTypes.push(TAG);
export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({

    // getUsers: builder.query<PageDataResponse<User>, number | void>({
      // query: (page = 1) => `posts?page=${page}`,
    getUsers: builder.query<CommonResponse<PageDataResponse<User>>, Partial<UsersSearchRequest>>({
      query: (param) => {
        console.log('usersApi Req ', param);
        return ({
          url: 'users/select',
          method: 'GET',
          params: param,
        })
      },
      // tagTypes 의 등록된 타입에 따라 tag를 생성 및 관리
      // page 목록의 경우 page가 변경되면 해당 page의 데이터를 다시 가져오기 때문에
      // page가 변경되면 해당 page의 tag를 invalidate 시키고 다시 가져옴
      providesTags: (result, error, param) => {
        if( error ) {
          console.log('getUsers', result, error, param);
          return [{ type: TAG, id: 'PARTIAL-LIST' }];
        }
        // tagId를 생성하여 tag 를 더 세분화하여 관리할 수 있음 (장:속도 향상, 단:캐시 관리가 어려움/복잡해짐/캐시가 너무 많아질 수 있음)
        // let tagId : string = `${param.page}-${param.size}-${param.sort}-${param.username}-${param.useNm}`;
        console.log('getUsers', result, error, param);
        if( result && result.data && result.data.content && result.data.content.length > 0 ) {
          return [
            ...result.data.content.map(({ id }) => ({ type: 'Users' as const, id })),
            // { type: 'Users', id: `${tagId}-PARTIAL-LIST` },
            { type: TAG, id: `PARTIAL-LIST` },
          ]
        } else {
          // return [{ type: 'Users', id: `${tagId}-PARTIAL-LIST` }];
          // return [{ type: 'Users', id: `${tagId}-PARTIAL-LIST` },{ type: 'Users', id: `PARTIAL-LIST` }];
          return [{ type: TAG, id: `PARTIAL-LIST` }];
        }
      }
    }),
    checkMember: builder.mutation<CommonResponse<User>, string>({
      query: (email) => ({
        url: `users/${email}/check`,
        method: 'GET',
      }),
    }),

    insertUser: builder.mutation<CommonResponse<User>, Partial<User>>({
      query: (body) => ({
        url: 'users',
        method: 'POST',
        body: body,
        responseHandler: (response) => response.json().then((data) => {
          if (response.ok) {
            if( data.code === 0 ){
              return data;
            }
            return Promise.reject(`[${data.code}] ${data.msg}`);
          }
          // return Promise.reject(data);
          return Promise.reject(`[${response.status}] ${response.statusText}`);
        })
      }),
      invalidatesTags: (result, error, body) => {
        return [{ type: TAG, id: 'PARTIAL-LIST' }]
      },

    }),

    updateUser: builder.mutation<CommonResponse<User>, Partial<User>>({
      query: (body) => ({
        url: 'users',
        method: 'PUT',
        body: body,
        responseHandler: (response) => response.json().then((data) => {
          if (response.ok) {
            if( data.code === 0 ){
              return data;
            }
            return Promise.reject(`[${data.code}] ${data.msg}`);
          }
          // return Promise.reject(data);
          return Promise.reject(`[${response.status}] ${response.statusText}`);
        })
      }),
      invalidatesTags: (result, error, body) => {
        return [{ type: TAG, id: 'PARTIAL-LIST' }]
      }
    }),

    deleteUser: builder.mutation<CommonResponse<User>, number>({
      query(id) {
        return {
          url: `users/${id}`,
          method: 'DELETE',
          responseHandler: (response) => response.json().then((data) => {
            if (response.ok) {
              if( data.code === 0 ){
                return data;
              }
              return Promise.reject(`[${data.code}] ${data.msg}`);
            }
            // return Promise.reject(data);
            return Promise.reject(`[${response.status}] ${response.statusText}`);
          })
        }
      },
      // Invalidates the tag for this Post `id`, as well as the `PARTIAL-LIST` tag,
      // causing the `listPosts` query to re-fetch if a component is subscribed to the query.
      invalidatesTags: (result, error, id) => [
        { type: TAG, id },
        { type: TAG, id: 'PARTIAL-LIST' },
      ],
    }),



  })
});

export const {
  useGetUsersQuery,
  useCheckMemberMutation,
  useInsertUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = usersApi;

