import {isNotEmpty} from "./commonUtil";

export const localSave = (key: string, value: string) => {
  key = 'license-' + key;
  window.localStorage.setItem(key, value);
}

export const localLoad = (key: string, defaultValue: string): string => {
  key = 'license-' + key;
  const value = window.localStorage.getItem(key);
  if (isNotEmpty(value)) {
    return value as string;
  }
  return defaultValue;
}

export const localRemove = (key: string) => {
  key = 'license-' + key;
  window.localStorage.removeItem(key);
}

export const localClear = () => {
  // license-로 시작하는 모든 키를 삭제
  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i);
    if (key?.startsWith('license-')) {
      window.localStorage.removeItem(key);
    }
  }
}

