// https://jsonplaceholder.typicode.com/

import {createApi, fetchBaseQuery, retry} from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://jsonplaceholder.typicode.com/",
  prepareHeaders: (headers, { getState }) => {
    // const token = (getState() as RootState).auth.token;
    // if (token) {
    //   headers.set("authorization", `Bearer ${token}`);
    // }
    return headers;
  },
});

// fetchBaseQueryWithRetries 는 fetchBaseQuery를 사용하며, retry를 사용할 수 있음
const baseQueryWithRetry = retry(baseQuery, { maxRetries: 3 }); // retry 는 재시도를 위한 옵션을 설정할 수 있음(기본값: 3)

export const testApi = createApi({
  reducerPath: "api",   // 기본적으로 api로 설정되어 있음 (configureStore에서 reducerPath로 설정한 것과 동일해야 함)
  baseQuery: baseQueryWithRetry,  // fetchBaseQuery 또는 fetchBaseQueryWithRetries 를 사용 (기본값: fetchBaseQuery) retry를 사용하려면 fetchBaseQueryWithRetries를 사용
  tagTypes: ["Todos"],            // tagTypes 각 query에 대한 tag를 설정 (invalidatesTags, providesTags에서 사용)
  endpoints: () => ({}),    // 다른 파일에서 endpoint를 정의하고 사용할 수 있도록 설정
});

// enhancedApi는 api를 사용하여 endpoint를 정의할 수 있도록 설정
// 테스트 코드에서 사용할 수 있도록 설정
export const enhancedApi = testApi.enhanceEndpoints({
  endpoints: () => ({
    getPost: () => 'test',
  }),
})
