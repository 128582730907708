import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NavGroupState {
  expandedNavGroup: string | null;
  expandedChildGroup: string | null;
  isMro: boolean | null;
}

const initialState: NavGroupState = {
  expandedNavGroup: null,
  expandedChildGroup: null,
  isMro: null,
};

const navGroupSlice = createSlice({
  name: 'navGroup',
  initialState,
  reducers: {
    setExpandedNavGroup(state, action: PayloadAction<string | null>) {
      state.expandedNavGroup = action.payload;
    },
    setExpandedChildGroup(state, action: PayloadAction<string | null>) {
      state.expandedChildGroup = action.payload;
    },
    setIsMro(state, action: PayloadAction<boolean | null>) { // isMro 설정 액션
      if(action.payload === true) {
        state.isMro = true;
      } else if(action.payload === false) {
        state.isMro = false;
      } else{
        state.isMro = null;
      }
      state.isMro = action.payload;
    },
    resetIsMro(state) {
      state.isMro = null;
    },
    closeNavGroups(state, action: PayloadAction<number>) {
      if (action.payload >= 1) {
        state.expandedChildGroup = null;
      } else {
        state.expandedNavGroup = null;
        state.expandedChildGroup = null;
      }
      state.isMro = null;
    },
  },
});

export const { setIsMro, resetIsMro, setExpandedNavGroup, setExpandedChildGroup, closeNavGroups } = navGroupSlice.actions;
export default navGroupSlice.reducer;