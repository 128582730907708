

export enum COLOR_TYPE {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  PRIMARY = 'primary',
  INFO = 'info',
  DARK = 'dark',
  LIGHT = 'light',
  TRANSPARENT = 'transparent',
}
export enum SIZE_TYPE {
  CONTENT = 'content',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  FULL = 'full',
}

export enum ICON_TYPE {
  SEARCH = 'search',
  LINK = 'link',
  USER = 'user',
  PASSWORD = 'password',
  TELEPHONE = 'tel',
  EMAIL = 'email',
  ANDROID = 'android',
  IOS = 'ios',
  SERVER = 'server',
  NUMBER = 'number',
  X = 'x',
  TRASH = 'trash',
  UPLOAD = 'upload',
  CIRCLE = 'circle',
  EXPAND_MORE = 'expand_more',
  EXPAND_LESS = 'expand_less',
  CALENDAR = 'calendar',
  RADIO = 'radio',
  OTP = 'otp',
  NONE = 'none',
  ARROW_UP = 'arrow_up',
  ARROW_DOWN = 'arrow_down',
  ARROW_UP_AND_DOWN = 'arrow_up_and_down',
  WARNING = 'warning',
  TEMPLE = 'temple',
  PAPER = 'paper',
  SETTING = 'setting',
  EDIT = 'edit',
  CHEVRON_LEFT = 'chevron_left',
  CHEVRON_RIGHT = 'chevron_right',
  RESET_ALT = 'reset_alt',
  EXCEL = 'excel',
  GROUP = 'group',
  MENU = 'menu',
  CHECK = 'check',
  ADD = 'add',
  TITLE = 'title',
  BODY_TEXT = 'body_text',
  LIST = 'list',
  CONTRACT_EDIT = 'contract_edit',
  MANAGEMENT = 'management',
  SALES = 'sales',
  BADGE = 'badge',
  APARTMENT = 'apartment',
  VERIFICATION  = 'verification',
  PACKAGE = 'package',
  LIST_SEARCH = 'list_search',
  DELIVERY = 'delivery',
  ENGEENIRING = 'engeeniring',
  WYSIWYG = 'wysiwyg',
  BULB = 'bulb',
  LICENSE = 'license',
  COMPUTER = 'computer',
  NEW = 'new',
  CONSTRUCTION = 'construction',
  CYCLE = 'cycle',
  DATECHECK = 'datecheck',
  VERSION = 'version',
  REPORT = 'report',
  POLICY = 'policy',
  MENUALBOOK = 'menualbook',
  LOCATION = 'location',
  HANDSHAKE = 'handshake',
}

export enum TEXTBOX_TYPE {
  LINK = 'link',
  USER = 'user',
  CALL = 'call',
  EMAIL = 'email',
  ANDROID = 'android',
  IOS = 'ios',
  SERVER = 'server',
  TEXT = '',
}

export enum AUTHORITY_TYPE {
  GUEST = 'ROLE_GUEST',
  USER = 'ROLE_USER',
  ADMIN = 'ROLE_ADMIN',
}

export enum SORT_TYPE {
  ASC = 'ASC',  // 오름차순
  DESC = 'DESC', // 내림차순
  UNSORTED = 'UNSORTED', // 정렬되지 않은 상태
  NONE = 'NONE', // 정렬 기능 없음
}

export enum NAV_NAME_TYPE {
  LICENSE_POLICIES = '라이선스 정책',
  LICENSES = '라이선스 관리',
  LICENSE_VERIFY = '라이선스 검증',
  LICENSE_TEMPLATES = '라이선스 템플릿 관리',
  PROJECTS_NEW = '신규 프로젝트',
  PROJECTS_MAINTAIN = '유지보수 전환 프로젝트',
  MROS = '유상 유지보수 계약',
  TSMP = '고객사 별 유지보수 절차',
  NOTICES = '기술지원 정보 공유',
  LICENSE_VERSIONS = '라이선스 생성기 버전 관리',
  LICENSE_GENERATORS = '라이선스 생성기 관리',
  SOLUTIONS = '솔루션 목록 관리',
  SALES_PERSONS = '영업대표 목록 관리',
  SALES_STATES = '영업 상태 관리',
  PROJECT_STATES = '프로젝트 상태 관리',
  SOLUTION_DELIVERYS = '솔루션 납품 목록 관리',
  MRO_STATES = '유상유지보수 상태',
  MRO_PERSON = '유상유지보수 담당자',
  INSPECT_STATES = '점검 형태',
  MRO_CYCLES = '점검 주기',
  USERS = '사용자 관리',
}