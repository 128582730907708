import "./ThreeWayLayout.css"
import Nav from "../component/Nav";
import React, {CSSProperties, useEffect, useState} from "react";
import Header from "../component/Header";
import {useAppDispatch} from "../redux/store";
import {useSelector} from "react-redux";
import {selectNavViewType, setNavViewType} from "../redux/reducer/slices/navBarSlice";
import routes, {ROUTE_TYPE} from "../page/Routes";
import {Route, Routes} from "react-router-dom";

export enum NAV_VIEW_TYPE {
  MAX = 'nav-layout--max',
  SLIM = 'nav-layout--slim',
  HIDE = 'nav-layout--hide',
  SLIM_HOVER = 'nav-layout--slim-hover',
}

type InnerSize = {
  width: number;
  height: number;
}

enum FIXE_SIZE {
  HEADER_HEIGHT = 64,
  // NAV_MAX_WIDTH = 255,
  NAV_MAX_WIDTH = 310,
  NAV_SLIM_WIDTH = 50,
  NAV_HIDE_WIDTH = 0,
}

const ThreeWayLayout = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [layoutInnerSize, setLayoutInnerSize] = useState<InnerSize>({width: window.innerWidth, height: window.innerHeight});
  const [isResize, setIsResize] = useState(false);
  const [viewPortSize, setViewPortSize] = useState<InnerSize>({width: window.innerWidth, height: window.innerHeight});
  const [navWidth, setNavWidth] = useState(FIXE_SIZE.NAV_MAX_WIDTH);
  const [isNavHover, setIsNavHover] = useState(false);
  const [headerHeight] = useState(FIXE_SIZE.HEADER_HEIGHT);
  const [threeWayLayoutStyle, setThreeWayLayoutStyle] = useState({
    '--cur-layout-width': ``,
    '--cur-side-nav-width': `${navWidth}px`,
    '--cur-header-height': `${headerHeight}px`,
    '--side-nav-hover-width': `${FIXE_SIZE.NAV_MAX_WIDTH}px`,
  });
  const navViewType = useSelector(selectNavViewType);
  const dispatch = useAppDispatch();


  useEffect(() => {
    const windowInnerSize = () => {
      setViewPortSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      setIsResize(true);
    }

    window.addEventListener('resize', windowInnerSize);

    return () => {
      window.removeEventListener('resize', windowInnerSize);
    }

  }, []);

  useEffect(() => {
    setThreeWayLayoutStyle({
      // -15px for scrollbar
      '--cur-layout-width': `${viewPortSize.width - 15}px`,
      '--cur-side-nav-width': `${navWidth}px`,
      '--cur-header-height': `${headerHeight}px`,
      '--side-nav-hover-width': `${FIXE_SIZE.NAV_MAX_WIDTH}px`,
    })
  }, [headerHeight, navWidth, viewPortSize.width]);

  useEffect(() => {
    if( isResize ) {
      dispatch(setNavViewType(viewPortSize.width < 1024 ? NAV_VIEW_TYPE.HIDE : NAV_VIEW_TYPE.MAX));
      setIsResize(false);
    }
  }, [dispatch, isResize, navViewType, viewPortSize]);



  useEffect(() => {
    if (isNavHover) {
      if (navViewType === NAV_VIEW_TYPE.SLIM) {
        dispatch(setNavViewType(NAV_VIEW_TYPE.SLIM_HOVER));
      }
    } else {
      if (navViewType === NAV_VIEW_TYPE.SLIM_HOVER) {
        dispatch(setNavViewType(NAV_VIEW_TYPE.SLIM));
      }
    }
  }, [dispatch, isNavHover, navViewType]);

  useEffect(() => {
    switch (navViewType) {
      case NAV_VIEW_TYPE.MAX:
        setNavWidth(FIXE_SIZE.NAV_MAX_WIDTH);
        break;
      case NAV_VIEW_TYPE.SLIM:
        setNavWidth(FIXE_SIZE.NAV_SLIM_WIDTH);
        break;
      case NAV_VIEW_TYPE.HIDE:
        setNavWidth(FIXE_SIZE.NAV_HIDE_WIDTH);
        break;
    }
  }, [navViewType]);



  return (
    <div className={'three-way-layout'} style={threeWayLayoutStyle as CSSProperties}>

      <div className={'main-layout'}>
        <div className={'content-layout'}>
          <Routes>
            {
              routes
                .filter((route) => route.type === ROUTE_TYPE.THREE_WAY_CONTENT)
                .map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<route.element/>}/>
                )
              })
            }
          </Routes>
        </div>
        <div className={'header-layout'}>
          <Header/>
        </div>

      </div>

      <div
        className={`nav-layout ${navViewType}`}
        onMouseOver={() => setIsNavHover(true)}
        onMouseOut={() => setIsNavHover(false)}
      >
        <Nav/>
      </div>
    </div>
  )
}

export default ThreeWayLayout;