import React, {useState} from "react";
import "./ImgBtn.css"
import Btn from "./Btn";
import {COLOR_TYPE, SIZE_TYPE} from "./TYPE";
import ImgBox from "./ImgBox";

interface ImgBtnProps {
  src : string;       // 이미지 경로
  size?: string;      // 이미지 크기
  onClick?: (e: React.MouseEvent<HTMLButtonElement> | undefined) => void; // 버튼 클릭 핸들러
}

function ImgBtn(props: ImgBtnProps) {
  const srcValue = props.src;
  const [size] = useState<React.CSSProperties>({
    width: props.size ?? '50px',
    height: props.size ?? '50px',
  })

  const clickHandler = (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <>
      <Btn
        className={`img-btn__btn ${COLOR_TYPE.TRANSPARENT} ${SIZE_TYPE.CONTENT}`}
        style={size}
        onClick={clickHandler}
      >
        <ImgBox src={srcValue} width={props.size} height={props.size}></ImgBox>
      </Btn>
    </>
  )
}

export default ImgBtn;