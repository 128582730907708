import {combineReducers} from "@reduxjs/toolkit";
import {testApi} from "./testApi";
import {api} from "./api";
import authSlice from "./slices/authSlice";
import navBarSlice from "./slices/navBarSlice";
import usersSlice from "./slices/usersSlice";
import modalSlice from "./slices/modalSlice";
import navGroupSlice from "./slices/navGroupSlice";

export const rootReducer = combineReducers({
  //Redux Toolkit Query's API reducer
  // ... other reducers
  auth: authSlice,
  navBar: navBarSlice,
  navGroup: navGroupSlice,
  modalStore: modalSlice,
  users: usersSlice,


  // RTK Query's API reducer
  [api.reducerPath]: api.reducer,
  [testApi.reducerPath]: testApi.reducer,
})

export const rootMiddleware = [
  // ... other middlewares
  api.middleware,
  testApi.middleware,
]

