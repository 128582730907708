import {useCheckJwtMutation} from "../redux/reducer/service/authApi";
import Btn from "./Btn";
import {COLOR_TYPE, ICON_TYPE, SIZE_TYPE} from "./TYPE";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useAppDispatch} from "../redux/store";
import {toggleHide} from "../redux/reducer/slices/navBarSlice";
import "./Header.css";
import ImgBtn from "./ImgBtn";
import Popper from "./Popper";
import {useNavigate} from "react-router-dom";
import {logout, selectToken, selectUser} from "../redux/reducer/slices/authSlice";
import useOutsideClick from "../hook/useOutSideClick";
import {useSelector} from "react-redux";
import {localClear} from "../util/localStorage";

enum HEADER_SIZE {
  MENU = '30px',
  PROFILE = '40px',
}

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isPopperVisible, setIsPopperVisible] = useState(false);
  const popperRef = useRef<HTMLDivElement | null>(null);
  const closePopper = useCallback(() => {
    if (popperRef && isPopperVisible) {
      setIsPopperVisible(false);
    }

  }, [popperRef, isPopperVisible]);
  const token = useSelector(selectToken);
  const myUser = useSelector(selectUser);
  const userName = myUser?.username;

  useOutsideClick(popperRef, closePopper);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checkJwt, {data: checkJwtData, isSuccess, isLoading, isError, error, reset}] = useCheckJwtMutation();

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkJwt(myUser || {});
    }, 500000); // 5분마다 실행
    // 컴포넌트가 언마운트될 때 인터벌 해제
    return () => clearInterval(intervalId);
  }, [checkJwt, myUser]);

  const logoutHandle = useCallback(() => {
    navigate('/g/login');
    localClear();
    dispatch(logout());
  }, [dispatch, navigate]);

  useEffect(() => {
    if (!userName) {
      alert('세션이 만료되었습니다. 다시 로그인해주세요.');
      logoutHandle();
    }
  }, [logoutHandle, userName]);



  useEffect(() => {
    if (isError) {
      console.log(isError, error);
      logoutHandle();
    }
    if (isSuccess || isError) {
      reset();
    }

  }, [isError, error, isSuccess, logoutHandle, reset]);


  const imgBtnClickHandle = useCallback(() => {
    setIsPopperVisible(!isPopperVisible);
  }, [isPopperVisible]);

  const qrGeneration = useCallback(() => {
    if (token && myUser) {
      return (
        'https://qrcode.tec-it.com/API/QRCode?data=otpauth://totp/NSHC_License_Management_System?secret='
        + myUser.secretKey
        + "&issuer=NSHC License Management System Admin"
      )
    } else {
      return '';
    }
  }, [token, myUser]);


  return (
    <header className="header">
      <Btn
        className={`header__menu ${COLOR_TYPE.TRANSPARENT} ${SIZE_TYPE.CONTENT}`}
        onClick={() => dispatch(toggleHide())}
        iconType={ICON_TYPE.MENU}
        iconSize={HEADER_SIZE.MENU}
      />
      <div className="target header__img-btn-container"
           aria-describedby="tooltip"
           ref={popperRef}
      >
        <ImgBtn
          // src={`${process.env.PUBLIC_URL}/images/spongebob.png`}
          src={qrGeneration()}
          size={HEADER_SIZE.PROFILE}
          onClick={imgBtnClickHandle}
        />
        {isPopperVisible && (
          <Popper placement="bottom">
            <div>{userName}</div>
            <Btn onClick={logoutHandle}>LogOut</Btn>
          </Popper>
        )}
      </div>
    </header>
  );
}


export default Header;
