import {createSlice} from "@reduxjs/toolkit";
import {ModalsProps, modalsProps} from "../../../component/modal/modals";


type ModalsState = {
  modalsProps: ModalsProps<object|string|undefined>[]
};



const initialState: ModalsState = {modalsProps: modalsProps};

const modalSlice = createSlice({
  name: "modalStore",
  initialState,
  reducers: {
    // modal 을 추가
    addModal(state, action: { payload: ModalsProps<object|string|undefined> }) {
      state.modalsProps.push(action.payload);
    },
    // modal 을 추가할 때, 기존에 있는 modal 이 있을 경우 기존 것을 사용
    setModal(state, action: { payload: ModalsProps<object|string|undefined> }) {
      // modal 을 추가할 때, 기존에 있는 modal 이 있을 경우 기존 것을 사용
      const modal = state.modalsProps.find((modal) => modal.name === action.payload.name);
      if( modal ) {
        modal.modalProps = action.payload.modalProps;
        return;
      }
      // 없을 경우 추가 되도록
      state.modalsProps.push(action.payload);
    },
    // modal 을 보일지 말지 선택
    showModal(state, action:{payload: string} ) {
      const modal = state.modalsProps.find((modal) => modal.name === action.payload);
      if( modal ) {
        modal.modalProps.isShow = true;
      }
    },
    closeModal(state, action:{payload: string} ) {
      const modal = state.modalsProps.find((modal) => modal.name === action.payload);
      if( modal ) {
        modal.modalProps.isShow = false;
      }
    },
    // modalProps 전체를 바꾸는 경우
    updateModal(state, action:{ payload: Partial<ModalsProps<object|string|undefined>> }) {
      const modal = state.modalsProps.find((modal) => modal.name === action.payload.name);
      if( modal && action.payload.modalProps ) {
        modal.modalProps = action.payload.modalProps;
      }
    },
    // modalProps 일부만 바꾸는 경우
    patchModal(state, action:{ payload: Partial<ModalsProps<object|string|undefined>> }) {
      const modal = state.modalsProps.find((modal) => modal.name === action.payload.name);
      if( modal ) {
        modal.modalProps = {...modal.modalProps, ...action.payload.modalProps};
      }
    },
    deleteModal(state, action:{ payload: string }) {
      const index = state.modalsProps.findIndex((modal) => modal.name === action.payload);
      if( index > -1 ) {
        state.modalsProps.splice(index, 1);
      }
    }
  }
});

export const {
  addModal,
  showModal,
  closeModal,
  updateModal,
  patchModal,
} = modalSlice.actions;

export const selectModals = (state: { modalStore: ModalsState }) => state.modalStore.modalsProps;

export default modalSlice.reducer;