
// Route exact path="/login" name="Login Page" element={<Login />}

import React from "react";

export enum ROUTE_TYPE {
  LAYOUT,
  ONE_WAY_CONTENT,
  THREE_WAY_CONTENT,
  OTHER,
}
export interface Route {
  path: string;
  name: string;
  element: React.LazyExoticComponent<any>;
  type: ROUTE_TYPE;
}



const routes: Route[] = [
  {
    path: "/",
    name: "Home",
    element:React.lazy(() => import("../layout/ThreeWayLayout") as any),
    type: ROUTE_TYPE.LAYOUT,
  },
  {
    path: "/g/*",
    name: "one-way",
    element:React.lazy(() => import("../layout/OneWayLayout") as any),
    type: ROUTE_TYPE.LAYOUT,
  },
  {
    path: "/login",
    name: "Login",
    element: React.lazy(() => import("./Login") as any),
    type: ROUTE_TYPE.ONE_WAY_CONTENT,
  },
  {
    path: "/u/*",
    name: "three-way",
    element: React.lazy(() => import("../layout/ThreeWayLayout") as any),
    type: ROUTE_TYPE.LAYOUT,
  },
  {
    path: "/licensePolicies",
    name: "LicensePolicy",
    element: React.lazy(() => import("./LicensePolicies/index") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/licensePolicies/:id",
    name: "LicensePolicy",
    element: React.lazy(() => import("./LicensePolicies/LicensePolicyView") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/licensePolicies/create",
    name: "LicensePolicy",
    element: React.lazy(() => import("./LicensePolicies/LicensePolicyCreate") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/licensePolicies/:id/modify",
    name: "LicensePolicy",
    element: React.lazy(() => import("./LicensePolicies/LicensePolicyModify") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/licenseGenerators",
    name: "LicenseGenerator",
    element: React.lazy(() => import("./LicenseGenerators/index") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/licenseGenerators/:id",
    name: "LicenseGenerator",
    element: React.lazy(() => import("./LicenseGenerators/LicenseGeneratorView") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/licenseGenerators/create",
    name: "LicenseGenerator",
    element: React.lazy(() => import("./LicenseGenerators/LicenseGeneratorCreate") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/licenseGenerators/:id/modify",
    name: "LicenseGenerator",
    element: React.lazy(() => import("./LicenseGenerators/LicenseGeneratorModify") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },

  {
    path: "/projects-new",
    name: "Project",
    element: React.lazy(() => import("./Projects/index") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/projects-maintain",
    name: "Project",
    element: React.lazy(() => import("./Projects/maintenaceIndex") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/projects/:id",
    name: "Project",
    element: React.lazy(() => import("./Projects/ProjectView") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/projects/create",
    name: "Project",
    element: React.lazy(() => import("./Projects/ProjectCreate") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/projects/:id/modify",
    name: "Project",
    element: React.lazy(() => import("./Projects/ProjectModify") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },

  {
    path: "/licenseTemplates",
    name: "LicenseTemplate",
    element: React.lazy(() => import("./LicenseTemplates/index") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/licenseTemplates/:id",
    name: "LicenseTemplate",
    element: React.lazy(() => import("./LicenseTemplates/LicenseTemplateView") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/licenseTemplates/create",
    name: "LicenseTemplate",
    element: React.lazy(() => import("./LicenseTemplates/LicenseTemplateCreate") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/licenseTemplates/:id/modify",
    name: "LicenseTemplate",
    element: React.lazy(() => import("./LicenseTemplates/LicenseTemplateModify") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },

  {
    path: "/licenses",
    name: "Licenses",
    element: React.lazy(() => import("./Licenses/index") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/licenses/create",
    name: "Licenses",
    element: React.lazy(() => import("./Licenses/LicenseCreate") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },

  {
    path: "/licenseVerify",
    name: "LicenseVerify",
    element: React.lazy(() => import("./LicenseVerify") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/mros",
    name: "Mro",
    element: React.lazy(() => import("./mro/index") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/mros/create",
    name: "Mro",
    element: React.lazy(() => import("./mro/MroCreate") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/mros/:id",
    name: "Mro",
    element: React.lazy(() => import("./mro/MroView") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/mros/:id/modify",
    name: "Mro",
    element: React.lazy(() => import("./mro/MroModify") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },

  {
    path: "/notices",
    name: "Notice",
    element: React.lazy(() => import("./Notices/index") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/notices/create",
    name: "Notice",
    element: React.lazy(() => import("./Notices/NoticeCreate") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/notices/:id",
    name: "Notice",
    element: React.lazy(() => import("./Notices/NoticeView") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/notices/:id/modify",
    name: "Notice",
    element: React.lazy(() => import("./Notices/NoticeModify") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },

  {
    path: "/admin/users",
    name: "Users",
    element: React.lazy(() => import("./admin/Users") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/admin/solutions",
    name: "Solution",
    element: React.lazy(() => import("./admin/Solutions") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/admin/salespersons",
    name: "salespersons",
    element: React.lazy(() => import("./admin/SalePersons") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/admin/salesStates",
    name: "SalesState",
    element: React.lazy(() => import("./admin/SalesStates") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/admin/projectStates",
    name: "ProjectState",
    element: React.lazy(() => import("./admin/ProjectStates") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/admin/licenseVersions",
    name: "LicenseVersion",
    element: React.lazy(() => import("./admin/LicenseVersions") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/admin/solutionDeliverys",
    name: "SolutionDelivery",
    element: React.lazy(() => import("./admin/SolutionDeliverys") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/mro/MroPerson",
    name: "MroPerson",
    element: React.lazy(() => import("./mro/MroPersons") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/mro/MroStates",
    name: "MroPerson",
    element: React.lazy(() => import("./mro/MroStates") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },

  {
    path: "/mro/inspectStates",
    name: "InspectState",
    element: React.lazy(() => import("./mro/InspectStates") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "mro/mroCycles",
    name: "MroCycle",
    element: React.lazy(() => import("./mro/MroCycles") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/tsmps",
    name: "Tsmp",
    element: React.lazy(() => import("./tsmp/index") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/tsmps/create",
    name: "TsmpCreate",
    element: React.lazy(() => import("./tsmp/TsmpCreate") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/tsmps/:id/modify",
    name: "TsmpModify",
    element: React.lazy(() => import("./tsmp/TsmpModify") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },
  {
    path: "/tsmps/:id",
    name: "TsmpView",
    element: React.lazy(() => import("./tsmp/TsmpView") as any),
    type: ROUTE_TYPE.THREE_WAY_CONTENT,
  },

];

export default routes;
