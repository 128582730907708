import {configureStore} from "@reduxjs/toolkit";
import {ConfigureStoreOptions} from "@reduxjs/toolkit/src/configureStore";
import {rootMiddleware, rootReducer} from "./reducer";
import {persistReducer, persistStore} from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import {useDispatch} from "react-redux";

const persistConfig = {
  key: 'root', // key는 reducer의 이름과 동일해야 함
  storage: sessionStorage, // storage 지정 (default: localStorage) +sessionStorage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({  // configureStoreSettings
    reducer: persistedReducer, // reducer 모음 (combineReducers)
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(
      {
        serializableCheck:false, // RTK Query의 API reducer는 serializableCheck를 통과하지 못함
        // immutableCheck: false, // 불변성 체크
      }
    ).concat(rootMiddleware),  // 기본 미들웨어 외에 추가할 미들웨어
    ...options, // preloadedState
  })

export const store = createStore();
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

