import './Nav.css';
import Logo from "./Logo";
import Btn from "./Btn";
import {AUTHORITY_TYPE, COLOR_TYPE, ICON_TYPE, NAV_NAME_TYPE} from "./TYPE";
import {RootState, useAppDispatch} from "../redux/store";
import {selectNavViewType, toggleSlimMax} from "../redux/reducer/slices/navBarSlice";
import NavItem from "./NavItem";
import NavGroup from "./NavGroup";
import React, {
  useCallback, useEffect, useState,
  // useState
} from "react";
import {NAV_VIEW_TYPE} from "../layout/ThreeWayLayout";
import {useSelector} from "react-redux";
import Icon from "./Icon";
import {selectUser} from "../redux/reducer/slices/authSlice";
import {
  setExpandedNavGroup,
  setExpandedChildGroup,
  closeNavGroups, resetIsMro,
} from '../redux/reducer/slices/navGroupSlice';
import {useLocation} from "react-router-dom";

const Nav = () => {
  const dispatch = useAppDispatch();
  const navViewType = useSelector(selectNavViewType);
  const myUser = useSelector(selectUser);


  const expandedNavGroup = useSelector((state: RootState) => state.navGroup.expandedNavGroup);
  const expandedChildGroup = useSelector((state: RootState) => state.navGroup.expandedChildGroup);
  const isMro = useSelector((state: RootState) => state.navGroup.isMro); // isMro 상태 가져오기

  const [newProjectSelect, setNewProjectSelect] = useState<boolean>(false);
  const [maintainProjectSelect, setMaintainProjectSelect] = useState<boolean>(false);

  const isSlim = useCallback(() => {
    return navViewType === NAV_VIEW_TYPE.SLIM;
  }, [navViewType]);

  const renderLogo = useCallback(() => {
    if (!isSlim()) {
      return <Logo className='MainLogo' to='/u/licensePolicies'
        // onClick={() => closeNavGroup(0)}
                   onClick={() => dispatch(closeNavGroups(0))}
      />;
    } else {
      return null; // Return null when slim, so the logo is not rendered
    }
  }, [isSlim, dispatch]);

  const handleNavGroupClick = (name: string) => {
    dispatch(setExpandedNavGroup(name === expandedNavGroup ? null : name));
    dispatch(setExpandedChildGroup(name === expandedNavGroup ? null : name));
  };

  const handleChildGroupClick = (parent: string, child: string | null) => {
    dispatch(setExpandedNavGroup(parent));
    dispatch(setExpandedChildGroup(child));
  };


  const location = useLocation();

  useEffect(() => {
    if (location.pathname.indexOf("projects") !== -1) {
      if (location.pathname.indexOf("projects-new") !== -1 || location.pathname.indexOf("projects-maintain") !== -1) {
        setNewProjectSelect(false);
        setMaintainProjectSelect(false);
        dispatch(resetIsMro());  // isMro 값을 초기화
      } else {
        // 유지보수 전환 프로젝트
        if (isMro === true) {
          dispatch(setExpandedNavGroup('프로젝트'));
          setMaintainProjectSelect(true);
          setNewProjectSelect(false);
        }
        // 신규 프로젝트
        else if (isMro === false) {
          dispatch(setExpandedNavGroup('프로젝트'));
          setNewProjectSelect(true);
          setMaintainProjectSelect(false);
        } else {
          setNewProjectSelect(false);
          setMaintainProjectSelect(false);
        }
      }
    } else {
      setNewProjectSelect(false);
      setMaintainProjectSelect(false);
    }

  }, [location, dispatch, isMro]);


  return (
    <div className={'nav'}>
      <div className={'nav__header'}>
        {renderLogo()}
      </div>
      <div className={'nav__body'}>
        <NavGroup
          fold={isSlim()}
          iconType={ICON_TYPE.LICENSE}
          value='라이선스'
          isExpanded={expandedNavGroup === '라이선스'}
          expandedChild={expandedNavGroup}
          onClick={() => handleNavGroupClick('라이선스')}
        >
          <NavItem
            fold={isSlim()}
            iconType={ICON_TYPE.TEMPLE}
            to='/u/licensePolicies'
            value={NAV_NAME_TYPE.LICENSE_POLICIES}
            depth={1}
          />
          <NavItem
            fold={isSlim()}
            iconType={ICON_TYPE.PACKAGE}
            to='/u/licenses'
            value={NAV_NAME_TYPE.LICENSES}
            depth={1}
          />
          <NavItem
            fold={isSlim()}
            iconType={ICON_TYPE.VERIFICATION}
            to='/u/licenseVerify'
            value={NAV_NAME_TYPE.LICENSE_VERIFY}
            depth={1}
          />
          <NavItem
            fold={isSlim()}
            iconType={ICON_TYPE.EDIT}
            to='/u/licenseTemplates'
            value={NAV_NAME_TYPE.LICENSE_TEMPLATES}
            depth={1}
          />
        </NavGroup>


        <NavGroup
          fold={isSlim()}
          iconType={ICON_TYPE.COMPUTER}
          value='프로젝트'
          isExpanded={expandedNavGroup === '프로젝트'}
          expandedChild={expandedNavGroup}
          onClick={() => handleNavGroupClick('프로젝트')}
        >
          <NavItem
            fold={isSlim()}
            iconType={ICON_TYPE.NEW}
            to='/u/projects-new'
            value={NAV_NAME_TYPE.PROJECTS_NEW}
            depth={1}
            isSelect={newProjectSelect}
          />
          <NavItem
            fold={isSlim()}
            iconType={ICON_TYPE.CONSTRUCTION}
            to='/u/projects-maintain'
            value={NAV_NAME_TYPE.PROJECTS_MAINTAIN}
            depth={1}
            isSelect={maintainProjectSelect}
          />
        </NavGroup>

        <NavItem
          fold={isSlim()}
          iconType={ICON_TYPE.PAPER}
          to='/u/mros'
          value={NAV_NAME_TYPE.MROS}
          // onClick={() => closeNavGroup(0)}
          onClick={() => dispatch(closeNavGroups(0))}
        />

        <NavGroup
          fold={isSlim()}
          iconType={ICON_TYPE.MENUALBOOK}
          value='기술지원 / 유지보수 정책'
          isExpanded={expandedNavGroup === '기술지원 / 유지보수 정책'}
          expandedChild={expandedNavGroup}
          onClick={() => handleNavGroupClick('기술지원 / 유지보수 정책')}
        >
          <NavItem
            fold={isSlim()}
            iconType={ICON_TYPE.POLICY}
            to='/u/tsmps'
            value={NAV_NAME_TYPE.TSMP}
            depth={1}
          />
          <NavItem
            fold={isSlim()}
            iconType={ICON_TYPE.REPORT}
            to='/u/notices'
            value={NAV_NAME_TYPE.NOTICES}
            depth={1}
          />
        </NavGroup>

        {
          myUser && myUser.authority === AUTHORITY_TYPE.ADMIN &&
            <NavGroup
                fold={isSlim()}
                iconType={ICON_TYPE.MANAGEMENT}
                value='관리자 설정'
                isExpanded={expandedNavGroup === '관리자 설정'}
                expandedChild={expandedNavGroup}
                onClick={() => handleNavGroupClick('관리자 설정')}
                onChildClick={(child) => handleChildGroupClick('관리자 설정', child)}
                depth={0}
            >
                <NavGroup
                    fold={isSlim()}
                    iconType={ICON_TYPE.MANAGEMENT}
                    value='라이선스 설정'
                    depth={1}
                    isExpanded={expandedChildGroup === '라이선스 설정'}
                    expandedChild={expandedChildGroup}
                    onChildClick={(child) => handleChildGroupClick('관리자 설정', child)}
                >
                    <NavItem
                        fold={isSlim()}
                        iconType={ICON_TYPE.VERSION}
                        to='/u/admin/licenseVersions'
                        value={NAV_NAME_TYPE.LICENSE_VERSIONS}
                        depth={2}
                    />
                    <NavItem
                        fold={isSlim()}
                        iconType={ICON_TYPE.PAPER}
                        to='/u/licenseGenerators'
                        value={NAV_NAME_TYPE.LICENSE_GENERATORS}
                        depth={2}
                    />

                </NavGroup>
                <NavGroup
                    fold={isSlim()}
                    iconType={ICON_TYPE.MANAGEMENT}
                    value='프로젝트 설정'
                    depth={1}
                    isExpanded={expandedChildGroup === '프로젝트 설정'}
                    expandedChild={expandedChildGroup}
                    onChildClick={(child) => handleChildGroupClick('관리자 설정', child)}
                >
                    <NavItem
                        fold={isSlim()}
                        iconType={ICON_TYPE.BULB}
                        to='/u/admin/solutions'
                        value={NAV_NAME_TYPE.SOLUTIONS}
                        depth={2}
                    />
                    <NavItem
                        fold={isSlim()}
                        iconType={ICON_TYPE.MANAGEMENT}
                        to='/u/admin/salespersons'
                        value={NAV_NAME_TYPE.SALES_PERSONS}
                        depth={2}
                    />
                    <NavItem
                        fold={isSlim()}
                        iconType={ICON_TYPE.SALES}
                        to='/u/admin/salesStates'
                        value={NAV_NAME_TYPE.SALES_STATES}
                        depth={2}
                    />

                    <NavItem
                        fold={isSlim()}
                        iconType={ICON_TYPE.WYSIWYG}
                        to='/u/admin/projectStates'
                        value={NAV_NAME_TYPE.PROJECT_STATES}
                        depth={2}
                    />
                    <NavItem
                        fold={isSlim()}
                        iconType={ICON_TYPE.DELIVERY}
                        to='/u/admin/solutionDeliverys'
                        value={NAV_NAME_TYPE.SOLUTION_DELIVERYS}
                        depth={2}
                    />
                </NavGroup>
                <NavGroup
                    fold={isSlim()}
                    iconType={ICON_TYPE.ENGEENIRING}
                    value='유지보수 설정'
                    depth={1}
                    isExpanded={expandedChildGroup === '유지보수 설정'}
                    expandedChild={expandedChildGroup}
                    onChildClick={(child) => handleChildGroupClick('관리자 설정', child)}

                >
                    <NavItem
                        fold={isSlim()}
                        iconType={ICON_TYPE.DATECHECK}
                        to='/u/mro/MroStates'
                        value={NAV_NAME_TYPE.MRO_STATES}
                        depth={2}
                    />
                    <NavItem
                        fold={isSlim()}
                        iconType={ICON_TYPE.ENGEENIRING}
                        to='/u/mro/MroPerson'
                        value={NAV_NAME_TYPE.MRO_PERSON}
                        depth={2}
                    />
                    <NavItem
                        fold={isSlim()}
                        iconType={ICON_TYPE.CALENDAR}
                        to='/u/mro/inspectStates'
                        value={NAV_NAME_TYPE.INSPECT_STATES}
                        depth={2}
                    />
                    <NavItem
                        fold={isSlim()}
                        iconType={ICON_TYPE.CYCLE}
                        to='/u/mro/mroCycles'
                        value={NAV_NAME_TYPE.MRO_CYCLES}
                        depth={2}
                    />
                </NavGroup>

                <NavItem
                    fold={isSlim()}
                    iconType={ICON_TYPE.BADGE}
                    to='/u/admin/users'
                    value={NAV_NAME_TYPE.USERS}
                    depth={1}
                    onClick={() => dispatch(closeNavGroups(1))}
                />
            </NavGroup>
        }


      </div>
      <div className='nav__footer'>
        <Btn
          className={COLOR_TYPE.TRANSPARENT}
          onClick={() => {
            dispatch(toggleSlimMax());
          }}>
          <div>
            <Icon className="nav__footer-icon"
                  iconType={navViewType === NAV_VIEW_TYPE.MAX ? ICON_TYPE.CHEVRON_LEFT : ICON_TYPE.CHEVRON_RIGHT}/>
          </div>
        </Btn>

      </div>
    </div>
  );
}

export default Nav;