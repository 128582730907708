// https://jsonplaceholder.typicode.com/

import {createApi, fetchBaseQuery, retry} from "@reduxjs/toolkit/query/react";
import {RootState} from "../store";

// http:// 붙이지 않으면 에러 발생
// export const baseUrl = "http://vitamin4u.net:21080/v1/";  // 사용하지 않음
export const baseUrl = "https://msd.nshc.net:8888/v1/";  // 최종 배포 서버
// export const baseUrl = "http://localhost:8888/v1/"; // 로컬
// export const baseUrl = "http://192.168.0.175:8888/v1/"; // 테스트 서버
// export const baseUrl = "http://192.168.20.208:8888/v1/";  // 서동민 매니저님 자리 테스트 서버
// export const baseUrl = "http://192.168.0.175:7777/v1/"; // staging 서버
// export const baseUrl = "http://192.168.10.146:8888/v1/"; // 종호매 자리

const baseQuery = fetchBaseQuery({
  // http:// 붙이지 않으면 에러 발생
  baseUrl: baseUrl,
  //cors 허용
  // mode: 'no-cors',

  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      // headers.set("authorization", `Bearer ${token}`);
      headers.set("authorization", `${token}`);
    }
    return headers;
  },
});

// fetchBaseQueryWithRetries 는 fetchBaseQuery를 사용하며, retry를 사용할 수 있음
const baseQueryWithRetry = retry(baseQuery, { maxRetries: 1 }); // retry 는 재시도를 위한 옵션을 설정할 수 있음(기본값: 3)

export const tagTypes: string[] = [];
export const api = createApi({

  // reducerPath: "api",   // 기본적으로 api로 설정되어 있음 (configureStore에서 reducerPath로 설정한 것과 동일해야 함)
  reducerPath: "splitApi",   // 기본적으로 api로 설정되어 있음 (configureStore에서 reducerPath로 설정한 것과 동일해야 함)
  baseQuery: baseQueryWithRetry,  // fetchBaseQuery 또는 fetchBaseQueryWithRetries 를 사용 (기본값: fetchBaseQuery) retry를 사용하려면 fetchBaseQueryWithRetries를 사용
  tagTypes: tagTypes,            // tagTypes 각 query에 대한 tag를 설정 (invalidatesTags, providesTags에서 사용)
  endpoints: () => ({}),    // 다른 파일에서 endpoint를 정의하고 사용할 수 있도록 설정
});

// enhancedApi는 api를 사용하여 endpoint를 정의할 수 있도록 설정
// 테스트 코드에서 사용할 수 있도록 설정
export const enhancedApi = api.enhanceEndpoints({
  endpoints: () => ({
    getPost: () => 'test',
  }),
})
