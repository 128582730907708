import './Loading.css';

interface LoadingProps {
  isLoading?: boolean;
}
const Loading = (props: LoadingProps) => {

  return (
    <>
      {props.isLoading !== false && (
        // <div className={'loading__back'}>
          <div className="one-div"></div>
        // </div>
      )}
    </>
  );
}

export default Loading;