import {createSlice} from "@reduxjs/toolkit";
import {usersApi} from "../service/usersApi";
import {PageDataResponse, User} from "../../../interface/commonInterface";

type UsersState = {
  pageData: PageDataResponse<User>;
}

const initialState: UsersState = {
  pageData:{
    "content": [
    ],
    "pageable": {
      "sort": {
        "empty": true,
        "unsorted": true,
        "sorted": false
      },
      "offset": 0,
      "pageNumber": 0,
      "pageSize": 10,
      "paged": true,
      "unpaged": false
    },
    "last": true,
    "totalPages": 1,
    "totalElements": 0,
    "first": true,
    "size": 10,
    "number": 0,
    "sort": {
      "empty": true,
      "unsorted": true,
      "sorted": false
    },
    "numberOfElements": 0,
    "empty": true
  }
}

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addMatcher(
      usersApi.endpoints.getUsers.matchFulfilled,
      (state, action) => {
        console.log(`extraReducers getUsers, matchFulfilled action.payload: `, action.payload );
        state.pageData = action.payload.data;
      }
    )
  }
});

// export const {
// } = usersSlice.actions;

export const selectUsersPageData = (state: {users: UsersState}) => state.users.pageData;

export default usersSlice.reducer;