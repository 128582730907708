import {useSelector} from "react-redux";
import {selectModals} from "../../redux/reducer/slices/modalSlice";
import React from "react";
import {modalsComponent} from "./modals";

// export interface Modals {
//   key: string;
//   modalProps: CommonModalProps;
//   component: React.LazyExoticComponent<FunctionComponent<any>>;
// }
const Tent = ({children, isShow}: {children: React.ReactNode, isShow: boolean}) => {
  return (
    <div className={`modal-tent ${isShow ? 'modal-tent--show' : 'modal-tent--hide'}`}>
      {children}
    </div>
  )
};

const ModalManager = () => {
  const modals = useSelector(selectModals);


  return (
    <>
      {
        modals && modals.map((modalInfo) => {
          // isShow가 true인 것만 렌더링
          // if (modalInfo.modalProps.isShow) {
          // modalsComponent 에서 같은 key를 찾은 뒤 component Render
          const modalComponent = modalsComponent.find((modal) => modal.name === modalInfo.modalProps.name);
          return (
            modalComponent && modalComponent.component &&
            <Tent key={modalInfo.modalProps.name} isShow={modalInfo.modalProps.isShow}>
                <modalComponent.component key={modalInfo.modalProps.name} {...modalInfo.modalProps} />
            </Tent>
          )
          // }
          // return null;
        })
      }
    </>
  )

}

export default ModalManager;