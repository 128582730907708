import React, {Suspense} from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import routes, {ROUTE_TYPE} from "./page/Routes";
import Loading from "./component/Loading";
import ModalManager from "./component/modal/ModalManager";


function App() {
  return (
    <div className="app">
        <Suspense fallback={<Loading/>}>
          <Routes>
            {
              routes.filter((route) => route.type === ROUTE_TYPE.LAYOUT).map((route) => {
                return (
                  <Route key={route.path} path={route.path} element={<route.element/>} />
                )
              })
            }
            {
              routes.filter((route) => route.type === ROUTE_TYPE.OTHER).map((route) => {
                return (
                  <Route key={route.path} path={route.path} element={<route.element/>} />
                )
              })
            }
          </Routes>
          <ModalManager/>
        </Suspense>
    </div>
  );
}

export default App;
