import React, {forwardRef, useEffect} from "react";
import "./Btn.css";
import Icon from "./Icon";
import {COLOR_TYPE, ICON_TYPE, SIZE_TYPE} from "./TYPE";


interface BtnProps {
  iconType?: ICON_TYPE; // 아이콘 종류
  name?: string; // 버튼 내용
  children?: React.ReactNode; // 버튼 내용
  onClick?: (e: React.MouseEvent<HTMLButtonElement>|undefined) => void; // 버튼 클릭 핸들러

  iconSize?: string;  // 아이콘 크기
  fontSize?: string;  // 버튼 내용 크기
  className?: string | SIZE_TYPE | COLOR_TYPE; // 버튼 클래스
  style?: React.CSSProperties;
  disabled?: boolean;
}

const Btn = forwardRef((props: BtnProps, ref: React.Ref<HTMLButtonElement>) => {
// function Btn(props: BtnProps) {
  const [varStyle, setVarStyle] = React.useState<object | undefined>({});

  const [customNameStyle] = React.useState({
    fontSize: props.fontSize ?? '',
  });

  useEffect(() => {
    let tmp = {};
    if( props.fontSize ) {
      tmp = {...tmp, '--font-size': props.fontSize,};
    }
    if( props.iconSize ) {
      tmp = {...tmp, '--icon-size': props.iconSize,};
    }
    setVarStyle({...tmp, ...props.style});
  }, [props.iconSize, props.fontSize, props.style]);
  function clickHandler(e: React.MouseEvent<HTMLButtonElement>) {
    if (props.onClick) {
      props.onClick(e);
    }
  }
  return (
    <button 
    className={`btn ${props.className ?? ''} ${props.className ?? ''}`} 
    ref={ref} 
    onClick={clickHandler} 
    style={varStyle}
    disabled={props.disabled ? true : !props.onClick}
    >
      {
        props.name &&
        <div className="btn__text" style={customNameStyle}>{props.name}</div>
      }
      {
        props.iconType &&
            <div className="btn__icon">
                <Icon iconType={props.iconType}/>
        </div>
      }
      {props.children}
    </button>
  );
});
Btn.displayName = 'Btn';

export default Btn;