import {api} from "../api";
import {CommonResponse, User} from "../../../interface/commonInterface";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;


// export interface AuthResponse {
//   user: User;
//   token: string;
// }

export interface LoginPwdRequest {
  username: string;
  password: string;
}

export interface LoginPwdResponse {
  data: User[];
  errors: string[];
  total_count: number;
}

export interface LoginOtpRequest extends LoginPwdRequest {
  otp: string;
}

export interface LoginOtpResponse {
  token: string;
}

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    loginPwd: builder.mutation<LoginPwdResponse, LoginPwdRequest>({
      query: (body) => ({
        url: `users/login`,
        method: "POST",
        body,
        // responseHandler: (response) => response.json().then((data) => {
        //   console.log("responseHandler reponse :: ", response);
        //   console.log("responseHandler data :: ", data);
        //   if (response.ok) {
        //     // 정상이라면 documents[0], total_count >= 1 이어야 함
        //     if (data.total_count >= 1) {
        //     }
        //     // 정상이라면 sendQRCode 요청
        //     return data;
        //   }
        //   return Promise.reject(data);
        // }),
      }),
    }),

    sendQRCode: builder.mutation<void, Pick<User, 'id'>>({
      query: ({id}) => ({
        url: `/users/sendQR/${id}`,
        method: "POST",
      }),
    }),

    loginOtp: builder.mutation<LoginOtpResponse, LoginOtpRequest>({
      query: (body) => {
        // console.log(`/login body `, body);
        return {
          url: `/login`,
          method: "POST",
          body,





          // responseHandler: (response) => {
          //   console.log(`/loginOtp responseHandler response `, response);
          //   if (response.ok) {
          //     return response;
          //   }
          //   else {
          //     return Promise.reject(response);
          //   }
          // }
          // responseHandler: (response) => response.json().then((data) => {
          //   if (response.ok) {
          //     // 변경된 Authorization jwt 를 response header 에서 가져와서 auth token 에 저장
          //     // data.token = response.headers.get("Authorization");
          //     // if( response.headers.get("Authorization") != null ) {
          //     //   useDispatch()(saveJwtToken({token: response.headers.get("Authorization")}));
          //     // }
          //     console.log(`/loginOtp responseHandler data ${data}`);
          //     return data;
          //   } else {
          //     console.log(`/login error ${data}`);
          //     return Promise.reject(data);
          //   }
          // }),
        }
      },
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: `logout`,
        method: "POST",
      }),
    }),

    checkJwt: builder.mutation<CommonResponse<User>, Partial<User>>({
      query: (jwt) => ({
        url: `/users/checkJwt`,
        method: "POST",
        body: jwt,
        responseHandler: (response) => response.json().then((data) => {
            if (data.message === "User token is expire.") {
              return error;
            }
          }
        ),
      })
    }),

  })
});

export const {
  useLoginPwdMutation,
  useSendQRCodeMutation,
  useLoginOtpMutation,
  useLogoutMutation,
  useCheckJwtMutation,
} = authApi;