import {createSlice} from "@reduxjs/toolkit";
import {NAV_VIEW_TYPE} from "../../../layout/ThreeWayLayout";

type NavBarState = {
  navViewType: NAV_VIEW_TYPE,
}

const initialState: NavBarState = {
  navViewType: NAV_VIEW_TYPE.MAX,
}

const navBarSlice = createSlice({
  name: "navBar",
  initialState,
  reducers: {
    toggleSlimMax(state) {
      switch (state.navViewType) {
        case NAV_VIEW_TYPE.MAX:
          state.navViewType = NAV_VIEW_TYPE.SLIM;
          break;
        case NAV_VIEW_TYPE.SLIM:
        case NAV_VIEW_TYPE.SLIM_HOVER:
          state.navViewType = NAV_VIEW_TYPE.MAX;
          break;
      }
    },
    toggleHide(state) {
      switch (state.navViewType) {
        case NAV_VIEW_TYPE.MAX:
        case NAV_VIEW_TYPE.SLIM:
        case NAV_VIEW_TYPE.SLIM_HOVER:
          state.navViewType = NAV_VIEW_TYPE.HIDE;
          break;
        case NAV_VIEW_TYPE.HIDE:
          state.navViewType = NAV_VIEW_TYPE.MAX;
      }
    },
    setNavViewType(state, action) {
      state.navViewType = action.payload;
    }

  },

});

export const {
  toggleSlimMax,
  toggleHide,
  setNavViewType,
} = navBarSlice.actions;

export const selectNavViewType = (state: { navBar: NavBarState }) => state.navBar.navViewType;

export default navBarSlice.reducer;