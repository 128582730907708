// npm i react-popper @popperjs/core 로 popper 설치
import React, {useEffect} from 'react';
import "./Popper.css";
import {createPopper, Instance} from '@popperjs/core';

interface PopperProps {
  className?: string;                                     // Popper 클래스
  placement?: 'top' | 'top-start' | 'top-end' |
    'left' | 'left-start' | 'left-end' |
    'right' | 'right-start' | 'right-end' |
    'bottom' | 'bottom-start' | 'bottom-end';             // 툴팁 위치
  children?: React.ReactNode;                             // TextBox 바디 컴포넌트
}

function Popper(props: PopperProps) {

  useEffect(() => {
    const target = document.querySelector('.target') as HTMLElement | null;
    const tooltip = document.querySelector('.popper__tooltip') as HTMLElement | null;

    if (target !== null && tooltip !== null) {
      const popperInstance: Instance = createPopper(target, tooltip, {
        placement: props.placement || 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10],     // 여기서 target으로부터 tooltip을 몇 px 만큼 띄워 조정할 지 설정
            },
          },
        ],
      });
      return () => {
        popperInstance.destroy();
      };
    }
  }, [props.placement]);



  return (
      <div className="popper__tooltip" role="tooltip">
        <div className="popper__arrow" data-popper-arrow></div>
        <div className="popper__contents">
          {props.children}
        </div>
      </div>
    );

}

export default Popper;